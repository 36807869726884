@import "../../assets/scss/theme.scss";

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: black !important;
}
